.post-content {
  h1 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h2 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h3 {
    @include relative-font-size(1.25);
  }
}
